import { useEffect } from "react";

const Merch = () => {
    useEffect(() => {
        // Redirect to the new URL
        window.location.href = 'https://merch.homixidegang.com/';
    }, []);

    return null;
};

export default Merch;
