import {
  faApple,
  faFacebookF,
  faInstagram,
  faSpotify,
  faTwitter,
  faYoutube,
  faTwitch,
  faReddit,
  faDiscord
} from "@fortawesome/free-brands-svg-icons";
import { faMusic } from "@fortawesome/free-solid-svg-icons";
import Twitter from "../../assets/images/X_Icon.png";

interface SocialIconsTypes {
  icon?: any;
  link: string;
  img?: string;
  isPandora?: boolean;
}

export const data: SocialIconsTypes[] = [
  {
    icon: faTwitch,
    link: "https://www.twitch.tv/homixide55555",
  },
  {
    icon: faReddit,
    link: "https://www.reddit.com/r/homixidegang/",
  },
  {
    icon: faDiscord,
    link: "https://discord.com/invite/homixidegang",
  },
  {
    icon: faInstagram,
    link: "https://www.instagram.com/homixide55555/",
  },
  {
    icon: faInstagram,
    link: "https://www.instagram.com/homixidemeechie5/",
  },
  {
    icon: faInstagram,
    link: "https://www.instagram.com/homixidebeno5/",
  },
  {
    img: Twitter,
    link: "https://twitter.com/HomixideX5",
  },
  {
    img: Twitter,
    link: "https://twitter.com/homixidebeno5",
  },
];
