import { Box } from "../../../components/StyledComponent";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RoutePattern } from "../../../routes/RoutePattern";
import SocialIcons from "../../../components/SocialIcons/SocialIcons";
import BG_DESKTOP from "../../../assets/videos/landingpage.mp4";
import BG_MOBILE from "../../../assets/videos/landingpage_mobile.mp4";
import BG_POSTER from "../../../assets/images/Merch Backdrop GIF.gif";
import styled from "styled-components";
import { isMobile } from "../../../constants";
import { ios } from "../../../helpers";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import Swal from "sweetalert2";

const VolumeBtn = styled.button`
  bottom: 7%;
  @media (max-width: 821px) {
    bottom: 14%;
  }

  @media (max-width: 1181px) and (max-height: 821px) {
    bottom: 13%;
  }
`;

const VideoPlayer = styled.video``;

const VideoContainer = styled.div`
  @media (max-width: 820px) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
`;

const PopupContainer = styled.div``;

function isSafari() {
  return (
    navigator.vendor &&
    navigator.vendor.indexOf("Apple") > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf("CriOS") == -1 &&
    navigator.userAgent.indexOf("FxiOS") == -1
  );
}

const Showcase = () => {
  const videoRef: any = useRef(null);
  const popupRef: any = useRef(null);
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {}, [showPopup]);

  function handleClick(value: string) {
    if (value === "merch") {
      window.open("https://merch.homixidegang.com/");
      return;
    }

    navigate("/" + value);
  }

  function hidePopup() {
    const popupDiv = popupRef.current as HTMLDivElement;
    setShowPopup(false);

    setTimeout(function () {
      popupDiv.style.display = "none";
    }, 500);
  }

  useEffect(() => {
    const video = videoRef.current as HTMLVideoElement;

    if (video.muted) {
      video.play();
    }

    // setTimeout(function() {
    //   showSwal();
    // }, 1000);
  }, []);

  const showSwal = () => {
    Swal.fire({
      // title: 'Error!',
      imageUrl:
        "https://linkstorage.linkfire.com/medialinks/images/17d470b8-97ff-4782-85b9-373aed5f0e2a/artwork-440x440.jpg",
      imageWidth: !isMobile ? "85%" : "undefined",
      title: "i5u5we5",
      width: !isMobile ? "26em" : "270px",
      confirmButtonColor: "#DA3F37",
      confirmButtonText: "Listen Now",
      customClass: {
        container: "!font-stevie-sans",
        confirmButton: "!font-stevie-sans !uppercase",
        title: "mb-[0.25em] !py-0 !md:py-2",
        actions: "!mt-0",
      },
      willOpen: () => {
        scrollTop();
      },
      willClose: () => {
        scrollTop();
      },
      didOpen: function () {
        var offset = document.body.scrollTop;
        document.body.style.top = offset * -1 + "px";
        document.body.classList.add("modal--opened");
      },
      didClose: function () {
        var offset = parseInt(document.body.style.top, 10);
        document.body.classList.remove("modal--opened");
        document.body.scrollTop = offset * -1;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        window.open("https://homixidegang.lnk.to/i5u5we5");
      }
    });
  };

  function scrollTop() {
    window.scrollTo({ top: 0, behavior: "auto" });
  }

  return (
    <Box
      id={RoutePattern.HOME}
      className={`relative flex flex-col justify-center items-center -mt-1 xl:mt-0 ${
        ios ? (isSafari() ? "h-[90vh]" : "h-[88vh]") : "h-[93vh]"
      } xl:h-screen w-full overflow-hidden`}
    >
      <Box className="z-30 flex flex-col justify-center items-center h-full w-full">
        <VideoContainer className="h-full w-full align-center">
          <VideoPlayer
            id="video_player"
            ref={videoRef}
            preload="metadata"
            playsInline
            autoPlay
            muted
            loop
            poster={BG_POSTER}
            src={isMobile ? BG_MOBILE : BG_DESKTOP}
            className="h-full w-full object-cover block object-center"
          ></VideoPlayer>
        </VideoContainer>
        <Box className="absolute flex flex-col justify-center items-center mb-8 xl:mb-20 2xl:mb-24 xl:mt-5">
          <div
            className="bg-black/0 w-[10rem] xl:w-[20rem] 2xl:w-[25rem] h-[9rem] xl:h-[18rem] 2xl:h-[22rem] mb-4 xl:mb-10 2xl:mb-14 cursor-pointer"
            onClick={() => handleClick("tour")}
          ></div>
          <div className="flex">
            <div
              className="bg-black/0 w-[10rem] xl:w-[20rem] 2xl:w-[25rem] h-[9rem] xl:h-[18rem] 2xl:h-[22rem] mx-3 xl:mx-5 2xl:mx-7 cursor-pointer"
              onClick={() => handleClick("videos")}
            ></div>
            <div
              className="bg-black/0 w-[10rem] xl:w-[20rem] 2xl:w-[25rem] h-[9rem] xl:h-[18rem] 2xl:h-[22rem] mx-2 xl:mx-3 2xl:mx-5 cursor-pointer"
              onClick={() => handleClick("merch")}
            ></div>
          </div>
        </Box>
        <Box className="absolute bottom-5 md:bottom-1">
          <SocialIcons />
        </Box>
      </Box>
      <PopupContainer
        ref={popupRef}
        className={`${
          showPopup ? "opacity-100" : "opacity-0"
        } transition-opacity ease-in-out delay-150 duration-300 absolute left-0 top-0 z-50 w-full ${
          ios ? (isSafari() ? "h-[90vh]" : "h-[88vh]") : "h-[93vh]"
        } xl:h-screen flex flex-col justify-center items-center bg-black/75`}
      >
        <Box className="flex flex-col justify-center items-center m-10 p-10 w-full md:w-1/2">
          <Box className="flex flex-col md:flex-row md:mb-5 w-full">
            <Box className="flex md:hidden flex-col relative pb-5 w-full md:w-1/2">
              <div className="text-center text-2xl pb-2 text-[#FF0000] uppercase font-bold">
                The New Mix Tape
              </div>
              <div className="text-center text-5xl text-[#FF0000] mt-2 mb-1 font-bold">
                i5u5we5
              </div>
              {/* <div className="text-center text-2xl pt-2 text-[#FFF3E0] uppercase font-bold">out now</div> */}
            </Box>
            <img
              src="https://linkstorage.linkfire.com/medialinks/images/17d470b8-97ff-4782-85b9-373aed5f0e2a/artwork-440x440.jpg"
              className="w-full md:w-1/2"
            />
            <Box className="flex flex-col relative md:mx-5 py-2 md:py-10 w-full md:w-1/2">
              <div className="hidden md:block text-center text-xl md:text-3xl 3xl:text-4xl text-[#FF0000] uppercase">
                The New Mix Tape
              </div>
              <div className="hidden md:block text-center text-3xl md:text-5xl 3xl:text-7xl text-[#FF0000] my-14">
                i5u5we5
              </div>
              {/* <div className="hidden md:block text-center text-xl md:text-3xl 3xl:text-4xl text-[#FFF3E0] uppercase">out now</div> */}
              <button
                className="md:absolute md:bottom-5 bg-transparent hover:bg-[#FF0000] text-[#FF0000] hover:text-black transition-all duration-500 border border-solid border-[#FF0000] w-full my-1 md:my-0 py-1 md:py-2 text-lg text-[#FFF3E0] font-bold uppercase"
                onClick={() => {
                  window.open("https://homixidegang.lnk.to/i5u5we5");
                }}
              >
                Listen Now
              </button>
            </Box>
          </Box>
          <Box className="flex w-full">
            <button
              className="bg-[#FF0000] w-full py-2 md:p-2 text-2xl md:text-4xl text-black border border-solid border-[#FF0000] font-bold uppercase hover:opacity-75 transition-all duration-500"
              onClick={hidePopup}
            >
              Enter Site
            </button>
          </Box>
        </Box>
      </PopupContainer>
    </Box>
  );
};

export default Showcase;
