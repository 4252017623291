import { useSelector } from "react-redux";
import { Box } from "../../components/StyledComponent";
import Showcase from "./Showcase/Showcase";

export default function Home() {
  return (
    <Box className="text-white w-full flex flex-col justify-center items-center">
      <Showcase />
    </Box>
  );
}
