import {
    Box,
  } from "../../../components/StyledComponent";
  import { useEffect, useRef, useState } from "react";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import SocialIcons from "../../../components/SocialIcons/SocialIcons";
  import { RoutePattern } from "../../../routes/RoutePattern";
  import BG_DESKTOP from "../../../assets/videos/backdrop.mp4";
  import BG_MOBILE from "../../../assets/videos/backdrop.mp4";
  import BG_POSTER from "../../../assets/images/Merch Backdrop GIF.gif";
  import styled from "styled-components";
  import { isMobile } from "../../../constants";
  import { ios } from "../../../helpers";
  import $ from "jquery";
  
  const VolumeBtn = styled.button`
    bottom: 7%;
    @media (max-width: 821px) {
      bottom: 14%;
    }
    
    @media (max-width: 1181px) and (max-height: 821px) {
      bottom: 13%;
    }
  `;
  
  const VideoPlayer = styled.video`
  `;
  
  const VideoContainer = styled.div`
    @media (max-width: 820px) {
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      position: absolute;
    }
  `;

  function isSafari() {
    return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && navigator.userAgent.indexOf('CriOS') == -1 && navigator.userAgent.indexOf('FxiOS') == -1;
  }
  
  const Newsletter = () => {
    const videoRef: any = useRef(null);
    
    return (
      <Box
        id={RoutePattern.NEWSLETTER}
        className={`relative flex flex-col justify-center items-center -mt-1 xl:mt-0 ${ios ? isSafari() ? "h-[90vh]" : "h-[88vh]" : "h-[93vh]"} xl:h-screen w-full overflow-hidden`}
      >
        <Box className="z-30 flex flex-col justify-center items-center h-full w-full">
          <VideoContainer
            className="h-full w-full align-center"
          >
            <VideoPlayer
              id="video_player"
              ref={videoRef}
              preload="metadata"
              playsInline
              autoPlay
              muted
              loop
              poster={BG_POSTER}
              src={isMobile ? BG_MOBILE : BG_DESKTOP}
              className="h-full w-full object-cover block object-center"
            ></VideoPlayer>
          </VideoContainer>
          <Box className="absolute w-full h-screen">
            <iframe src="https://forms.umusic-online.com/forms/-Nrvuq1_cqq904_F02Fn" className="w-full h-full bg-transparent" allowTransparency={true} />
          </Box>
          <Box className="z-30 absolute bottom-5 md:bottom-1">
            <SocialIcons />
          </Box>
        </Box>

      </Box>
    );
  };
  
  export default Newsletter;
  