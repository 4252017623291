import { RoutePattern } from "../../routes/RoutePattern";

export const data = [
  {
    name: RoutePattern.HOME,
    to: "/",
    isRoute: false,
  },
  {
    name: RoutePattern.TOUR,
    to: RoutePattern.TOUR,
    isRoute: false,
  },
  {
    name: RoutePattern.VIDEOS,
    to: RoutePattern.VIDEOS,
    isRoute: false,
  },
  {
    name: RoutePattern.MERCH,
    to: "https://merch.homixidegang.com/",
    isRoute: false,
  },
  {
    name: RoutePattern.NEWSLETTER,
    to: RoutePattern.NEWSLETTER,
    isRoute: false,
  },
];
