import { AnimatePresence } from 'framer-motion';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Home, Videos, Tour, Newsletter } from '../app/index';
import Merch from '../app/Home/Merch/Merch2';
import PageNotFound from '../app/PageNotFound/PageNotFound';

export default function AppRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/tour" element={<Tour />} />
        <Route path="/merch" element={<Merch />} />
        <Route path="/newsletter" element={<Newsletter />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </AnimatePresence>
  );
}
